import React from 'react'
import { StyleSheet } from 'react-native'
import Image from '../Image'
import CoolGradient from "app/assets/cool-gradient.webp"

const CoolGradientFallback = () => (
  <Image source={CoolGradient} style={[StyleSheet.absoluteFill]} />
)


export default CoolGradientFallback
