import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';

export default function useWindowDimensions(delay: number = 350) {
  const [dims, setDims] = useState(() => Dimensions.get('window'));

  useEffect(() => {
    function _handleChange({ window }) {
      if (window != null && window !== undefined) {
        setDims(window);
      }
    }

    const handleChange =
      Platform.OS === 'web' ? debounce(_handleChange, delay) : _handleChange;
    Dimensions.addEventListener('change', handleChange);

    // We might have missed an update between calling `get` in render and
    // `addEventListener` in this handler, so we set it here. If there was
    // no change, React will filter out this update as a no-op.
    setDims(Dimensions.get('window'));

    return () => {
      // Only available on web
      if (Dimensions.removeEventListener) {
        Dimensions.removeEventListener('change', handleChange);
      }
    };
  }, [delay]);

  return dims;
}
