import {
  useCreate,
  useInvalidate,
  useResource,
  useResourceList,
} from 'app/hooks/use-resource/use-resource';
import React, { useCallback, useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Keyboard,
  Platform,
  useWindowDimensions,
} from 'react-native';

import useCopy from 'app/hooks/use-copy';
import Button, { ButtonType } from 'app/components/Button';
import TextInput from 'app/components/TextInput';
import LinkData from 'app/components/LinkData';
import Animated, {
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedKeyboard,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withSpring,
} from 'react-native-reanimated';
import Modal, { modalAnimationConfig } from 'app/components/Modal';
import ThemeView, { makeThemeStyle } from 'app/components/ThemeView';
import LayoutAnimation, {
  CoolLayoutAnimationType,
} from 'app/components/LayoutAnimation';
import { useToast } from 'app/hooks/use-toast';
import useIsMobile from 'app/hooks/use-is-mobile';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import { PortalHost } from '@gorhom/portal';
import Pressable from 'app/components/Pressable';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Tips from 'app/components/Tips';

const shakeAnimatedConfig = {
  mass: 0.2,
  stiffness: 700,
  damping: 20,
};

function SaveLink({ route, navigation }: ScreenProps<typeof Routes.SAVE_LINK>) {
  const windowDimensions = useWindowDimensions();
  const isMobile = useIsMobile();

  // Grab from params
  const { params = {} } = route;
  const [url, setUrl] = useState();
  const linkDataWidth = Platform.select({
    web: isMobile ? windowDimensions.width - 16 : 351,
    default: windowDimensions.width - 16,
  });

  const [linkDataId, setLinkDataId] = useState<string | null>(null);
  const { ready, linkData } = useResource('link_data', linkDataId, {
    fetch: false,
  });

  const invalidateSetting = useInvalidate('setting');
  const [loading, setLoading] = useState(false);
  const setToast = useToast();
  const modalAnimatedValue = useSharedValue(0);
  const shakeAnimatedValue = useSharedValue(0);

  const copy = useCopy([
    'createLinkUrlCancel',
    'createLinkUrlPlaceholder',
    'createLinkUrlConfirm',
    'homeLinkAdded',
  ]);

  const createLinkData = useCreate('link_data');
  const createLink = useCreate('link');

  const onClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onRequestClose = useCallback(() => {
    ReactNativeHapticFeedback.trigger('impactLight');
    Keyboard.dismiss();
    modalAnimatedValue.value = withSpring(0, modalAnimationConfig, () => {
      runOnJS(onClose)();
    });
  }, [onClose, modalAnimatedValue]);

  const submitLink = useCallback(async () => {
    if (!linkDataId) {
      return;
    }

    try {
      await createLink(
        {
          type: 'link',
          relationships: {
            link_data: {
              data: {
                id: linkDataId,
                type: 'link_data',
              },
            },
          },
        },
        {
          invalidateLists: ['stuff'],
        },
      );
    } catch (error) {
      shakeAnimatedValue.value = withSequence(
        withSpring(-1, shakeAnimatedConfig),
        withRepeat(withSpring(1, shakeAnimatedConfig), 5, true),
        withSpring(0, shakeAnimatedConfig),
      );

      invalidateSetting('me');

      return;
    }

    onRequestClose();
    setToast({
      type: 'info',
      text: copy.homeLinkAdded,
      delay: 2000,
    });
  }, [
    createLink,
    linkDataId,
    onRequestClose,
    copy.homeLinkAdded,
    setToast,
    invalidateSetting,
    shakeAnimatedValue,
  ]);

  const onChangeUrl = useCallback(
    async (text: string) => {
      setUrl(text);

      if (
        // URL validation
        !/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
          text,
        )
      ) {
        return;
      }

      let parsedUrl = text;
      if (!parsedUrl.includes('http')) {
        parsedUrl = `https://${parsedUrl}`;
      }

      setLoading(true);

      try {
        const _linkData = await createLinkData({
          attributes: {
            url: parsedUrl,
          },
          type: 'link_data',
        });

        setLinkDataId(_linkData.id);
      } catch (error) {
        setLoading(false);
      }
    },
    [createLinkData],
  );

  const onBlur = useCallback(() => {
    // On iOS we need to close on blur to handle dragging the keyboard
    // down to dismiss the save link modal. On web, we don't want to close
    // on blur so we can handle tips.
    if (!url && Platform.OS === 'ios') {
      onRequestClose();
    }
  }, [url, onRequestClose]);

  useEffect(() => {
    if (loading && linkData && linkData.attributes.status === 'success') {
      setLoading(false);
    }
  }, [linkData, loading]);

  // If loaded with a URL, then load immediately
  useEffect(() => {
    if (params.url) {
      onChangeUrl(params.url);
    }
  }, [params.url, onChangeUrl]);

  const formStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateY: interpolate(modalAnimatedValue.value, [0, 1], [-100, 0]),
        },
        {
          translateX: interpolate(modalAnimatedValue.value, [0, 1], [50, 0]),
        },
        {
          scaleY: interpolate(modalAnimatedValue.value, [0, 1], [-0.8, -1]),
        },
        {
          scaleX: interpolate(modalAnimatedValue.value, [0, 1], [0.8, 1]),
        },
      ],
    }),
    [modalAnimatedValue],
  );

  const linkDataAnimatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateX: interpolate(shakeAnimatedValue.value, [-1, 1], [-7, 7]),
        },

        {
          scaleY: -1,
        },
      ],
    }),
    [shakeAnimatedValue],
  );

  const keyboard = useAnimatedKeyboard();
  const scrollViewAnimatedStyle = useAnimatedStyle(() => ({
    paddingVertical: interpolate(
      keyboard.height.value,
      [0, 200],
      [48, 12],
      Extrapolation.CLAMP,
    ),
  }));

  // This is rendered on iOS within a native transparentModal view. In order for the modal itself
  // not to cover our content, we need to render the portal within the modal. This is an easy way
  // to do that. Alternatively, we probably could have used FullWindowOverlay
  return (
    <>
      <View style={StyleSheet.absoluteFill}>
        <PortalHost name="saveLink" />
      </View>
      <Modal
        visible
        onRequestClose={onRequestClose}
        transparent
        darkenBackground
        animatedValue={modalAnimatedValue}
        centered
        hostName="saveLink"
        // This fixes layout issues on mobile web
        keyboardAvoidingView={Platform.OS !== 'web'}
      >
        <Pressable
          style={StyleSheet.absoluteFillObject}
          onPress={onRequestClose}
        />
        <Animated.ScrollView
          keyboardDismissMode="interactive"
          keyboardShouldPersistTaps="handled"
          style={[styles.scrollView, scrollViewAnimatedStyle]}
          contentContainerStyle={styles.scrollViewContentContainer}
          centerContent={Platform.OS === 'web' && !isMobile}
        >
          {
            // NOTE: This needs to be here as well as above. Couldn't find another
            // way to have the entire background clickable.
          }
          <Pressable
            style={StyleSheet.absoluteFillObject}
            onPress={onRequestClose}
          />
          <ThemeView
            lightStyle={styles.modalContentLight}
            darkStyle={styles.modalContentDark}
            style={[{ width: linkDataWidth }, formStyle]}
          >
            <TextInput
              value={url}
              onChangeText={onChangeUrl}
              placeholder={copy.createLinkUrlPlaceholder}
              style={styles.modalTextInput}
              autoFocus
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect={false}
              keyboardType="url"
              spellCheck={false}
              onBlur={onBlur}
            />
            <View style={styles.buttons}>
              <Button
                type={ButtonType.secondary}
                onPress={onRequestClose}
                style={styles.button}
                shadow={false}
              >
                {copy.createLinkUrlCancel}
              </Button>
              <Button
                type={ButtonType.primary}
                disabled={!ready}
                onPress={submitLink}
                pending={loading}
                style={styles.button}
              >
                {copy.createLinkUrlConfirm}
              </Button>
            </View>
          </ThemeView>
          {linkDataId ? (
            <Animated.View style={[styles.linkData, linkDataAnimatedStyle]}>
              <LayoutAnimation type={CoolLayoutAnimationType.up}>
                <LinkData linkDataId={linkDataId} width={linkDataWidth} />
              </LayoutAnimation>
            </Animated.View>
          ) : (
            <Animated.View
              style={[
                styles.linkData,
                linkDataAnimatedStyle,
                formStyle,
                { width: linkDataWidth },
              ]}
            >
              <LayoutAnimation type={CoolLayoutAnimationType.up}>
                <Tips
                  query={{
                    'filter[type]': 'save_link',
                    'filter[platform]': Platform.OS,
                  }}
                />
              </LayoutAnimation>
            </Animated.View>
          )}
        </Animated.ScrollView>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  modalTextInput: {
    flex: 1,
  },
  linkData: {
    marginTop: 12,
  },
  ...makeThemeStyle(
    'modalContent',
    {
      padding: 12,
      borderRadius: 20,
      gap: 12,
      overflow: 'hidden',
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  buttons: {
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: 12,
  },
  button: {
    flex: 1,
  },
  scrollViewContentContainer: {
    paddingHorizontal: 8,
    ...(Platform.OS === 'web'
      ? {
        transform: [
          {
            scaleY: -1,
          },
        ],
        // This is required on mobile web to push the scroll container to the total height. Without
        // this the -1 y scale wouldn't work.
        flex: 1,
      }
      : {}),
  },
  scrollView: {
    ...(Platform.OS !== 'web'
      ? {
        transform: [
          {
            scaleY: -1,
          },
        ],
        overflow: 'visible',
      }
      : {}),
  },
  container: {},
});

export default SaveLink;
