import useCopy from 'app/hooks/use-copy';
import React, { ReactNode } from 'react';
import PinIcon from '../Icons/Pin';
import Searches from '../Searches';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import Text, { TextType } from '../Text';
import { useResource } from 'app/hooks/use-resource/use-resource';
import Topics from '../Topics/Topics';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import SmartSearch from '../SmartSearch/SmartSearch';
import ExplorableGroup, {
  EXPLORABLE_GROUP_EXPAND_PADDING,
} from './ExplorableGroup';
import CollectionItem from '../CollectionItem';
import Ellipsis from '../Icons/Ellipsis';
import Collections from '../Collections';

interface Props {
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  id: string;
  paddingHorizontal: number;
}

const ExplorableItem = ({ onChangeQuery, id, paddingHorizontal }: Props) => {
  const copy = useCopy([
    'homePinnedSearchesPlaceholderWeb',
    'homePinnedSearchesPlaceholderIOS',
  ]);
  const { explorable, ready } = useResource('explorable', id);

  if (!ready) {
    return null;
  }

  let content: ReactNode = null;

  if (explorable.attributes.type == 'searches') {
    content = (
      <View style={{ paddingHorizontal }}>
        <Searches
          onChangeQuery={onChangeQuery}
          searches={explorable.relationships.searches.data.map(
            ({ id: searchId }) => searchId,
          )}
          placeholder={
            <Text type={TextType.subheadline} color="gray11">
              {Platform.OS === 'web' ? (
                <>
                  {copy.homePinnedSearchesPlaceholderWeb[0]}
                  {'  '}
                  <Ellipsis />
                  {'  '}
                  {copy.homePinnedSearchesPlaceholderWeb[1]}
                </>
              ) : (
                copy.homePinnedSearchesPlaceholderIOS
              )}
            </Text>
          }
        />
      </View>
    );
  }

  if (explorable.attributes.type == 'topics') {
    content = (
      <Topics
        topics={explorable.relationships.topics.data.map(
          ({ id: searchId }) => searchId,
        )}
        onChangeQuery={onChangeQuery}
        paddingHorizontal={paddingHorizontal}
      />
    );
  }

  if (
    explorable.attributes.type == 'smart_search' &&
    explorable.relationships.smart_search.data?.id
  ) {
    content = (
      <SmartSearch
        smartSearchId={explorable.relationships.smart_search.data.id}
        onChangeQuery={onChangeQuery}
      />
    );
  }

  if (
    explorable.attributes.type == 'collection' &&
    explorable.relationships.collection.data?.id
  ) {
    content = (
      <CollectionItem
        collectionId={explorable.relationships.collection.data.id}
        onPress={(collection) =>
          onChangeQuery({
            'filter[collection.id]': collection.id,
          })
        }
      />
    );
  }

  if (explorable.attributes.type == 'collections') {
    content = (
      <View style={{ paddingHorizontal }}>
        <Collections
          onChangeQuery={onChangeQuery}
          collections={explorable.relationships.collections.data.map(
            ({ id: collectionId }) => collectionId,
          )}
          placeholder={
            Platform.OS === 'web' ? (
              <Text type={TextType.subheadline} color="gray11">
                {copy.homePinnedSearchesPlaceholderWeb[0]}
                {'  '}
                <Ellipsis />
                {'  '}
                {copy.homePinnedSearchesPlaceholderWeb[1]}
              </Text>
            ) : (
              copy.homePinnedSearchesPlaceholderIOS
            )
          }
        />
      </View>
    );
  }

  if (explorable.attributes.type == 'group') {
    content = (
      <ExplorableGroup paddingHorizontal={paddingHorizontal}>
        {explorable.relationships.explorables.data.map((explorableRelation) => (
          <ExplorableItem
            key={explorableRelation.id}
            onChangeQuery={onChangeQuery}
            paddingHorizontal={paddingHorizontal}
            id={explorableRelation.id}
          />
        ))}
      </ExplorableGroup>
    );
  }

  if (content) {
    const title = explorable.attributes.title;
    content = (
      <View
        style={[
          styles.container,
          explorable.attributes.type === 'topics' && styles.containerTopics,
          explorable.attributes.type === 'group' && styles.containerGroup,
        ]}
      >
        {title && (
          <View style={{ paddingHorizontal }}>
            <Text type={TextType.subheadlineEmphasized} color="LayeredGray">
              {title}
            </Text>
          </View>
        )}
        {content}
      </View>
    );
  }

  return content;
};

const styles = StyleSheet.create({
  container: {
    gap: 6,
    overflow: 'visible',
  },
  containerGroup: {
    // This accounts for the extra padding applied to ExplorableGroups. See ExplorableGroup.tsx.
    gap: 6 - EXPLORABLE_GROUP_EXPAND_PADDING,
    marginBottom: -EXPLORABLE_GROUP_EXPAND_PADDING,
  },
  containerTopics: {
    marginTop: -36,
  },
});

export default React.memo(ExplorableItem);
