import React, {
  useMemo,
  Dispatch,
  useRef,
  useSyncExternalStore,
  useCallback,
} from 'react';

import { createContext, useContext } from 'use-context-selector';

import makeFetcher, {
  FetcherOptions,
  FetcherResponseList,
  FetcherResponseSingle,
  JSONAPIError,
} from './make-fetcher';
import { Resource } from './types';
import { produce } from 'immer';

export type RequestStatus = 'pending' | 'success' | 'error' | 'invalidated';

export default function makeProvider<ValidResource extends Resource<string>>({
  fetcher,
}: {
  fetcher: ReturnType<typeof makeFetcher<ValidResource>>;
}) {
  type ResourceTypes = ValidResource['type'];
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  type ResourceEntities = {
    [type in ResourceTypes]?: {
      [id: string]: TypeToResource[type] | undefined;
    };
  };

  type ResourceLists = {
    [type in ResourceTypes]?: {
      [url: string]: {
        data: string[];
        meta: {
          count: number;
        };
        links: {
          next?: string;
        };
      };
    };
  };
  interface ResourceRequests {
    [url: string]: {
      requestType: 'list' | 'retrieve';
      type: ResourceTypes;
      status: RequestStatus;
      error?: JSONAPIError;
    };
  }

  interface ResourceState {
    requests: ResourceRequests;
    entities: ResourceEntities;
    lists: ResourceLists;
    parents: {
      [id: string]: {
        type: ResourceTypes;
        id: string;
        relationshipName: string;
      }[];
    };
  }

  // ACTIONS

  interface Action<ActionType extends string = string> {
    type: ActionType;
    meta: { url: string; type: ResourceTypes };
  }
  type FetchResourcePendingAction = Action<'fetch_resource_pending'>;
  type FetchResourceSuccessAction = Action<'fetch_resource_success'> & {
    payload: FetcherResponseSingle<ValidResource>['data'];
  };
  type FetchResourceErrorAction = Action<'fetch_resource_error'> & {
    payload: { error: JSONAPIError };
  };
  type FetchResourceListPendingAction =
    Action<'fetch_resource_list_pending'> & {
      meta: Action['meta'] & {
        listKey: string;
      };
    };
  type FetchResourceListSuccessAction =
    Action<'fetch_resource_list_success'> & {
      payload: FetcherResponseList<ValidResource>['data'];
      meta: Action['meta'] & {
        listKey: string;
        paginated?: boolean;
      };
    };
  type FetchResourceListErrorAction = Action<'fetch_resource_list_error'> & {
    payload: { error: JSONAPIError };
    meta: Action['meta'] & {
      listKey: string;
    };
  };
  type FetchResourceDeleteAction = {
    type: 'delete_resource_success';
    payload: {
      id: string;
    };
    meta: {
      type: ResourceTypes;
    };
  };
  type FetchResourceDeleteListAction = {
    type: 'delete_resource_list_success';
    payload: {};
    meta: Action['meta'] & {
      listKey: string;
    };
  };
  type InvalidateResourceAction = {
    type: 'invalidate_resource';
    payload: { url: string };
    meta: { type: ResourceTypes };
  };
  type InvalidateListTypeAction = {
    type: 'invalidate_list_type';
    meta: { type: ResourceTypes };
  };

  type Actions =
    | FetchResourcePendingAction
    | FetchResourceSuccessAction
    | FetchResourceErrorAction
    | FetchResourceListPendingAction
    | FetchResourceListSuccessAction
    | FetchResourceListErrorAction
    | FetchResourceDeleteAction
    | FetchResourceDeleteListAction
    | InvalidateResourceAction
    | InvalidateListTypeAction;

  // REDUCER

  const defaultState: ResourceState = {
    requests: {},
    entities: {},
    lists: {},
    parents: {},
  };

  function reducer(state: ResourceState, action: Actions) {
    return produce(state, (draft) => {
      function addToParents(
        parentType: ResourceTypes,
        parentId: string,
        childId: string,
        relationshipName: string,
      ) {
        draft.parents[childId] = draft.parents[childId] || [];
        draft.parents[childId].push({
          type: parentType,
          id: parentId,
          relationshipName,
        });
      }

      function addEntity(entity: ValidResource) {
        if (!entity) {
          return;
        }
        const entitiesForType = draft.entities[entity.type] || {};
        entitiesForType[entity.id] = entity;

        draft.entities[entity.type] = entitiesForType;

        for (const [relationshipName, relationship] of Object.entries(
          entity.relationships,
        )) {
          const relations = relationship.data;

          if (Array.isArray(relations)) {
            relations.forEach((relation) =>
              addToParents(
                entity.type,
                entity.id,
                relation.id,
                relationshipName,
              ),
            );
          } else if (relations) {
            addToParents(
              entity.type,
              entity.id,
              relations.id,
              relationshipName,
            );
          }
        }
      }

      function deleteEntity(type: ResourceTypes, id: string) {
        const nextEntitiesForType = draft.entities[type];

        if (!nextEntitiesForType) {
          return;
        }

        // Delete the entity from the entity list for this type
        delete nextEntitiesForType[id];
        draft.entities[type] = nextEntitiesForType;

        // Delete the entity from any parents
        for (const parent of draft.parents[id] || []) {
          const entitiesForParentType = draft.entities[parent.type];

          if (!entitiesForParentType) {
            break;
          }

          // Delete the entity from the relationships of the parent entity
          const parentEntity = entitiesForParentType[parent.id];
          if (!parentEntity) {
            // Sometimes the parent might have already been removed
            break
          }

          const parentRelationshipInfo =
            parentEntity.relationships[parent.relationshipName];

          if (Array.isArray(parentRelationshipInfo.data)) {
            parentRelationshipInfo.data = parentRelationshipInfo.data.filter(
              (r) => r.id !== id,
            );
          } else {
            parentRelationshipInfo.data = null;
          }
          // parentEntity.relationships[relationship] = parentRelationshipInfo;
          // entitiesForParentType[parentId] = parentEntity;

          draft.entities[parent.type] = entitiesForParentType;
        }

        // Cleanup the parent info
        delete draft.parents[id];

        const nextListsForType = draft.lists[type];
        if (!nextListsForType) {
          return;
        }

        // Delete from any other lists that contain this entity
        for (const [key, list] of Object.entries(nextListsForType)) {
          nextListsForType[key].data = list.data.filter((i) => i !== id);
        }
      }

      switch (action.type) {
        case 'fetch_resource_pending':
        case 'fetch_resource_list_pending': {
          const existing = draft.requests[action.meta.url] || {};

          existing.requestType =
            action.type === 'fetch_resource_pending' ? 'retrieve' : 'list';
          existing.type = action.meta.type;
          existing.status = 'pending';

          draft.requests[action.meta.url] = existing;

          break;
        }

        case 'fetch_resource_success':
        case 'fetch_resource_list_success': {
          const { payload, type, meta } = action;

          if (!payload) {
            break;
          }

          draft.requests[meta.url] = {
            requestType:
              action.type === 'fetch_resource_success' ? 'retrieve' : 'list',
            type: meta.type,
            status: 'success',
            error: null,
          };

          if (type === 'fetch_resource_success') {
            addEntity(payload.data);
          } else {
            const existingList = (draft.lists[meta.type] || {})[meta.listKey];

            // If paginated, we keep the existing IDs
            const listIds =
              meta.paginated && existingList ? existingList.data : [];

            for (const entity of payload.data) {
              listIds.push(entity.id);

              addEntity(entity);
            }

            const listsForType = draft.lists[meta.type] || {};

            listsForType[meta.listKey] = {
              ...payload,
              data: listIds,
            };

            draft.lists[meta.type] = listsForType;
          }

          if (payload.included) {
            for (const includedResource of payload.included) {
              if (!includedResource.type || !includedResource.id) {
                continue;
              }

              addEntity(includedResource);
            }
          }

          break;
        }

        case 'fetch_resource_error':
        case 'fetch_resource_list_error': {
          draft.requests[action.meta.url] = {
            requestType:
              action.type === 'fetch_resource_error' ? 'retrieve' : 'list',
            status: 'error',
            error: action.payload.error,
            type: action.meta.type,
          };
          break;
        }

        case 'delete_resource_success': {
          const {
            payload: { id },
            meta,
          } = action;

          deleteEntity(meta.type, id);

          break;
        }

        case 'delete_resource_list_success': {
          const {
            meta: { listKey, type },
          } = action;
          const listsForType: ResourceLists[ResourceTypes] =
            draft.lists[type] || {};

          if (!listsForType[listKey]) {
            break;
          }

          for (const entityId of listsForType[listKey].data) {
            deleteEntity(type, entityId);
          }

          break;
        }

        case 'invalidate_resource': {
          const existing = draft.requests[action.payload.url] || {};
          existing.status = 'invalidated';

          draft.requests[action.payload.url] = existing;
          break;
        }

        case 'invalidate_list_type': {
          const type = action.meta.type;

          const nextRequests = draft.requests;

          for (const [url, info] of Object.entries(nextRequests)) {
            if (info.requestType !== 'list' || info.type !== type) {
              continue;
            }

            nextRequests[url].status = 'invalidated';
          }

          draft.requests = nextRequests;

          break;
        }

        default:
          break;
      }
    });
  }

  type ResourceContextShape = {
    dispatch: Dispatch<Actions>;
    subscribe: (callback: () => void) => () => void;
    onError: (error: JSONAPIError) => void;
    resourceStateRef: React.MutableRefObject<ResourceState | null> | null;
  };

  const ResourceContext = createContext<ResourceContextShape>({
    dispatch: () => { },
    subscribe: () => () => { },
    onError: () => { },
    resourceStateRef: null,
  });

  // PROVIDER

  interface ResourceProviderProps {
    children?: React.ReactNode;
    initialState?: ResourceState;
    dispatch?: React.Dispatch<Actions>;
    onError?: (error: JSONAPIError) => void;
  }

  function ResourceProvider({
    children,
    dispatch: dispatchOverride,
    onError = () => { },
    initialState = defaultState,
  }: ResourceProviderProps) {
    const resourceStateRef = useRef<ResourceState>(initialState);
    const listeners = useRef<(() => void)[]>([]);

    const subscribe = useCallback((callback: () => void) => {
      listeners.current.push(callback);

      return () => {
        const index = listeners.current.indexOf(callback);
        if (index > -1) {
          listeners.current.splice(index, 1);
        }
      };
    }, []);

    const dispatch: React.Dispatch<Actions> = useMemo(() => {
      if (dispatchOverride) {
        return dispatchOverride;
      }

      return (action) => {
        resourceStateRef.current = reducer(resourceStateRef.current, action);

        for (const listener of listeners.current) {
          listener();
        }
      };
    }, [dispatchOverride]);

    const context = useMemo(
      () => ({
        dispatch,
        resourceStateRef,
        subscribe,
        onError,
      }),
      [dispatch, subscribe, onError],
    );

    return (
      <ResourceContext.Provider value={context}>
        {children}
      </ResourceContext.Provider>
    );
  }

  const useRequest = (url: string | null) => {
    const { resourceStateRef, subscribe } = useContext(ResourceContext);

    const getSnapshot = () => {
      const state = resourceStateRef?.current;

      if (!url || !state) {
        return null;
      }

      return state.requests[url];
    };

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
  };

  function useList<Type>(type: Type, listKey: string) {
    const { resourceStateRef, subscribe } = useContext(ResourceContext);

    const getSnapshot = () => {
      const state = resourceStateRef?.current;
      if (!state) {
        return null;
      }

      const lists = state.lists[type];

      if (!lists) {
        return null;
      }

      return lists[listKey] || null;
    };

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
  }

  function useEntity<Type>(type: Type, id: string | null) {
    const { resourceStateRef, subscribe } = useContext(ResourceContext);

    const getSnapshot = () => {
      const state = resourceStateRef?.current;
      if (!state) {
        return null;
      }

      const entities = state.entities[type];

      if (!entities || !id) {
        return null;
      }

      return entities[id] || null;
    };

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
  }

  function useResourceDispatch() {
    const { dispatch, subscribe } = useContext(ResourceContext);

    const getSnapshot = () => {
      return dispatch;
    };

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
  }

  function useResourceStateRef() {
    const { resourceStateRef, subscribe } = useContext(ResourceContext);

    const getSnapshot = () => {
      return resourceStateRef;
    };

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
  }

  function useResourceFetcher() {
    const { onError } = useContext(ResourceContext);
    fetcher;

    const fetcherWithError = useCallback(
      async (url: string, options?: FetcherOptions) => {
        try {
          return await fetcher(url, options);
        } catch (error) {
          if (error instanceof JSONAPIError) {
            onError(error);
          }

          throw error;
        }
      },
      [onError],
    );

    return fetcherWithError;
  }

  return {
    defaultState,
    ResourceProvider,
    useResourceDispatch,
    useRequest,
    useList,
    useEntity,
    useResourceStateRef,
    useResourceFetcher,
    reducer,
  };
}
